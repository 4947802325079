import React from 'react'
import { graphql } from 'gatsby'
import { get } from 'lodash'
import { Layout, ContentWithSideMenu } from 'components'
import { AccordionContent, Body, Hero, MultiColumnBlurb, FullWidthImage } from 'slices/service'

export const query = graphql`
  query serviceQuery($uid: String!) {
    prismic {
      menu(uid: "service", lang: "en-us") {
        title
        items {
          name
          show_in_side_menu
          link {
            _linkType
            __typename
            ... on PRISMIC_Service {
              _meta {
                uid
              }
            }
          }
        }
      }
      service(uid: $uid, lang: "en-us") {
        _meta {
          uid
        }
        title
        hero_image
        body {
          ... on PRISMIC_ServiceBodyAccordion_content {
            type
            fields {
              title
              description
            }
          }
          ... on PRISMIC_ServiceBodyBody {
            type
            primary {
              body_title
              body_content
            }
          }
          ... on PRISMIC_ServiceBodyFull_width_image {
            type
            primary {
              image
            }
          }
          ... on PRISMIC_ServiceBody3_column_blurb {
            type
            fields {
              column_icon
              column_title
              column_description
            }
          }
        }
        body_below {
          ... on PRISMIC_ServiceBody_belowAccordion_content {
            type
            fields {
              title
              description
            }
          }
          ... on PRISMIC_ServiceBody_belowBody {
            type
            primary {
              body_title
              body_content
              variant
            }
          }
          ... on PRISMIC_ServiceBody_belowFull_width_image {
            type
            primary {
              image
            }
          }
          ... on PRISMIC_ServiceBody_below3_column_blurb {
            type
            primary {
              variant
            }
            fields {
              column_icon
              column_title
              column_description
            }
          }
        }
      }
    }
  }
`

const Service = ({
  data: {
    prismic: { service, menu },
  },
}) => {
  return (
    <Layout>
      <Hero data={service} />
      <ContentWithSideMenu menu={menu} uid={get(service, '_meta.uid')}>
        {service.body &&
          service.body.map((slice, idx) => {
            switch (slice.type) {
              case 'accordion_content':
                return <AccordionContent key={idx} data={slice} />
              case 'body':
                return <Body key={idx} data={slice} />
              case 'full_width_image':
                return <FullWidthImage key={idx} data={slice} />
              case '3_column_blurb':
                return <MultiColumnBlurb key={idx} data={slice} />
              default:
                return null
            }
          })}
      </ContentWithSideMenu>
      {service.body_below && (
        <>
          {service.body_below.map((slice, idx) => {
            switch (slice.type) {
              case 'accordion_content':
                return <AccordionContent key={idx} data={slice} />
              case 'body':
                return <Body key={idx} data={slice} belowTheFold />
              case 'full_width_image':
                return <FullWidthImage key={idx} data={slice} />
              case '3_column_blurb':
                return <MultiColumnBlurb fullWidth key={idx} data={slice} />
              default:
                return null
            }
          })}
        </>
      )}
    </Layout>
  )
}

Service.query = query

export default Service
