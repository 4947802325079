import React from 'react'
import PrismicDOM from 'prismic-dom'
import system from '@rebass/components'
import { Flex, Text, Box, Image } from 'rebass'
import { colors, fonts } from 'theme'
import { ContentContainer } from 'components'

const Container = system(
  {
    extend: Flex,
    justifyContent: 'center',
    p: '0px',
  },
  'space',
)

const FullContainer = system(
  {
    extend: Flex,
    flex: 1,
    flexDirection: ['column', 'column', 'row'],
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    maxWidth: ['100%', '740px', '1100px'],
    width: '100%',
    p: '0px',
  },
  'space',
  'width',
  'flex',
  'maxWidth',
)

const ItemContainer = system(
  {
    extend: Flex,
    flex: 1,
    mb: ['45px', '45px', '0px'],
  },
  'space',
  'flex',
)

const IconContainer = system(
  {
    extend: Flex,
    alignItems: 'center',
    height: '63px',
    justifyContent: 'center',
    mb: '20px',
    width: '63px',
    minWidth: '63px',
    mr: '30px',
  },
  'flex',
  'borderRadius',
  'color',
  'height',
  'space',
  'minWidth',
  'width',
)

const Icon = system(
  {
    extend: Image,
    height: '48px',
    width: '48px',
  },
  'height',
  'width',
)

const Content = system({
  extend: Box,
})

const Title = system(
  {
    extend: Text,
    fontSize: '24px',
    fontFamily: fonts.main,
    lineHeight: '1.25',
    fontWeight: '700',
    color: colors.mediumBlack,
    mb: '20px',
  },
  'space',
  {
    letterSpacing: '0.05em',
  },
)

const Description = system(
  {
    extend: Text,
  },
  {
    '&>*': {
      letterSpacing: '0.05em',
      color: colors.lightBlack,
      lineHeight: '1.47',
      fontSize: '15px',
      fontFamily: fonts.main,
    },
    p: {
      marginTop: '0px',
    },
  },
)

export const MultiColumnBlurb = ({ data, fullWidth }) => {
  const { fields: columns, primary: { variant } } = data

  const colorForVariant = variant => {
    switch (variant) {
      case 'White':
        return colors.white
      case 'Dark Teal':
        return colors.darkTeal
      default:
        return fullWidth ? colors.mediumGray : colors.white
    }
  }

  const bg = colorForVariant(variant)

  return (
    <ContentContainer pt="45px" px="0px" bg={bg} justifyContent="flex-start">
      <Container>
        <FullContainer>
          {columns.map((item, idx) => (
            <ItemContainer pr={idx % 2 === 0 ? '15px' : '0px'} key={idx}>
              <IconContainer>
                {item.column_icon && <Icon src={item.column_icon.url} />}
              </IconContainer>
              <Content>
                {item.column_title && (
                  <Title>{PrismicDOM.RichText.asText(item.column_title)}</Title>
                )}
                {item.column_description && (
                  <Description
                    dangerouslySetInnerHTML={{
                      __html: PrismicDOM.RichText.asHtml(item.column_description),
                    }}
                  />
                )}
              </Content>
            </ItemContainer>
          ))}
        </FullContainer>
      </Container>
    </ContentContainer>
  )
}
