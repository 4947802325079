import React, { useState } from 'react'
import PrismicDOM from 'prismic-dom'
import system from '@rebass/components'
import { Box, Flex, Text } from 'rebass'
import posed from 'react-pose'
import { theme, colors, fonts } from 'theme'
import { Plus as PlusBase } from 'styled-icons/boxicons-regular/Plus'
import { Minus as MinusBase } from 'styled-icons/boxicons-regular/Minus'

const Container = system(
  {
    extend: Box,
    flex: 1,
  },
  'space',
  'color',
)

const AccordionContainer = system({
  extend: Box,
})

const AccordionHeader = system(
  {
    extend: Flex,
    alignItems: 'center',
    bg: colors.headerTeal,
    borderBottom: `2px solid ${theme.colors.lightTeal}`,
    pl: '10px',
  },
  'borderBottom',
  'color',
  'space',
  {
    cursor: 'pointer',
  },
)

const AccordionTitle = system(
  {
    extend: Text,
    color: colors.background,
    fontFamily: fonts.main,
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '.1em',
    mx: '15px',
    py: '17px',
  },
  'space',
)

const AccordionDescription = posed.div({
  closed: { height: 0, padding: '20px 10px 4px', overflow: 'hidden' },
  open: { height: 'auto', padding: '20px 10px 4px', overflow: 'hidden' },
})

const Content = system(
  {
    extend: Text,
    fontSize: '16px',
    color: 'white30',
    py: '17px',
    fontFamily: fonts.main,
  },
  'space',
  {
    '&>*': {
      letterSpacing: '0.05em',
      color: '#777',
      lineHeight: '1.47',
      fontSize: '15px',
      fontFamily: fonts.main,
    },
    p: {
      marginTop: '0px',
    },
  },
)

const Plus = system(
  {
    extend: PlusBase,
    width: '24px',
    height: '24px',
    color: colors.background,
  },
  'width',
  'height',
  'color',
)

const Minus = system(
  {
    extend: MinusBase,
    width: '24px',
    height: '24px',
    color: colors.background,
  },
  'width',
  'height',
  'color',
)

export const AccordionContent = ({ data }) => {
  const { fields: content } = data

  const [open, setOpen] = useState(-1)

  return (
    <Container>
      {content.map((item, idx) => (
        <AccordionContainer key={idx}>
          <AccordionHeader onClick={() => setOpen(open === idx ? -1 : idx)}>
            {open === idx && <Minus />}
            {open !== idx && <Plus />}
            <AccordionTitle>{PrismicDOM.RichText.asText(item.title)}</AccordionTitle>
          </AccordionHeader>
          <AccordionDescription pose={open === idx ? 'open' : 'closed'}>
            <Content
              dangerouslySetInnerHTML={{
                __html: item.description ? PrismicDOM.RichText.asHtml(item.description) : null,
              }}></Content>
          </AccordionDescription>
        </AccordionContainer>
      ))}
    </Container>
  )
}
