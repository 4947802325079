import React from 'react'
import system from '@rebass/components'
import { Image as ImageBase } from 'rebass'

const Image = system(
  {
    extend: ImageBase,
    width: '100%',
    mb: '40px',
  },
  'width',
  'space',
)

export const FullWidthImage = ({
  data: {
    primary: { image },
  },
}) => <Image src={image.url} />
