import React from 'react'
import PrismicDOM from 'prismic-dom'
import system from '@rebass/components'
import { Text, Box } from 'rebass'
import { colors, fonts } from 'theme'
import { ContentContainer } from 'components'

const Container = system(
  {
    extend: Box,
    flex: 1,
  },
  'color',
)

const Title = system(
  {
    extend: Text,
    fontSize: ['24px', '24px', '30px'],
    fontWeight: '700',
    fontFamily: fonts.main,
    color: colors.black,
    mb: '24px',
  },
  'space',
  {
    letterSpacing: '.1em',
    textTransform: 'uppercase',
  },
)

const Content = system(
  {
    extend: Text,
    mb: '50px',
    fontFamily: fonts.main,
    fontSize: '15px',
    lineHeight: '1.6',
    color: '#777777',
  },
  'space',
  {
    letterSpacing: '0.075em',
  },
)

export const Body = ({ data, belowTheFold }) => {
  const { primary: content } = data
  const { body_title: title, body_content: text } = content
  const { variant } = content

  const renderBody = () => (
    <Container>
      {title && (
        <Title color={colorForVariant() === colors.darkTeal ? colors.white : colors.black}>
          {PrismicDOM.RichText.asText(title)}
        </Title>
      )}
      {text && (
        <Content
          color={colorForVariant() === colors.darkTeal ? colors.white : '#777777'}
          dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(text) }}
        />
      )}
    </Container>
  )

  const colorForVariant = () => {
    switch (variant) {
      case 'Gray':
        return colors.mediumGray
      case 'Dark Teal':
        return colors.darkTeal
      default:
        return colors.white
    }
  }

  const bg = colorForVariant()

  return belowTheFold ? (
    <ContentContainer bg={bg} pb={0} pt="45px">
      {renderBody()}
    </ContentContainer>
  ) : (
    renderBody()
  )
}
